<template>
    <CModal
        color="primary"
        :closeOnBackdrop=false
        :show.sync="employeeModal"
        title="Seleccione su empleado"
    >
        <CRow>
            <CCol col="12" lg="12">
                <CSelect 
                    :placeholder="$t('employees.placeholder')" 
                    :label="$t('employees.title')" 
                    :options="employees"
                    :value.sync="employee_id"
                    :plain="true"
                ></CSelect>
            </CCol>
        </CRow>
        <template #footer>
            <CButton @click="getCurrentWeek" color="primary">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>

export default {
    name: "ModalEmployee",
    data() {
        return {
            employee_id: null,
        }
    },
    props: ['employeeModal', "employees"],
    methods: {
        getCurrentWeek() {
            this.$emit('getCurrentWeek', this.employee_id);
        }
    }
}
</script>
