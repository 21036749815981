<template>
    <div>
        <Loading :isLoading="isLoading" />
        <CRow style="height:100% !important">
            <CCol col="12" xl="12" style="height:100% !important">
                <CCard style="height:100% !important">
                    <CCardBody height="100%">
                        <div class="row d-flex justify-content-end mr-0 mb-3">
                            <div class="col-auto pr-0">
                                <CButton color="secondary" @click="showModalLocation">
                                    <CIcon :content="printIcon" /> Imprimir Recibo
                                </CButton>
                            </div>
                            <div class="col-auto pr-0">
                                <CButtonGroup>
                                    <CButton color="primary" variant='outline' @click="getLastWeek">Anterior</CButton>
                                    <CButton color="primary" variant='outline' @click="getCurrentWeek()">Hoy</CButton>
                                    <CButton color="primary" variant='outline' @click="getBeforeWeek">Siguiente</CButton>
                                </CButtonGroup>
                            </div>
                            <div class="col-2 pr-0">
                                &nbsp;
                            </div>
                            <div class="col-auto pr-0">
                                <CButton color="info" @click="employeeModal = true">
                                    <CIcon :content="userIcon" /> Cambiar de usuario
                                </CButton>
                            </div>
                            <div class="col-auto pr-0">
                                <CButton color="primary" @click="showModalLocation">
                                    <CIcon :content="locationIcon" /> Agregar Localidad
                                </CButton>
                            </div>
                            <div class="col-auto pr-0">
                                <CButton color="warning" @click="showModalIncidence">
                                    <CIcon :content="addIcon" /> Agregar Incidencia
                                </CButton>
                            </div>
                            <div class="col-auto pr-0">
                                <CButton color="success" @click="savePayroll">
                                    <CIcon :content="saveIcon" /> Guardar
                                </CButton>
                            </div>
                        </div>
                        <gantt-elastic
                            :tasks="tasks"
                            :options="options"
                            @tasks-changed="tasksUpdate"
                            @chart-task-click="onTaskClick"
                            @options-changed="optionsUpdate"
                            @dynamic-style-changed="styleUpdate"
                        >
                        </gantt-elastic>
                    </CCardBody>  
                </CCard>
            </CCol>
            <ModalIncidence 
                @addTask="addTask" 
                :isEmployee="false"
                :incidence="incidence" 
                :employees="employees" 
                :incidences="incidences" 
                :incidenceModal="incidenceModal" 
            />
            <ModalLocation
                :isEmployee="false"
                :location="location" 
                :locations="locations" 
                :employees="employees" 
                @addLocation="addLocation" 
                :locationModal="locationModal" 
            />
            <ModalDelete
                @deleteTask="deleteTask" 
                :deleteTaskModal="deleteTaskModal" 
            />
            <ModalEmployee
                :employees="employees" 
                :employeeModal="employeeModal" 
                @getCurrentWeek="getCurrentWeek" 
            />
        </CRow>
    </div>
</template>

<script>
import axios from 'axios';
import GanttElastic from "gantt-elastic";
import Loading from "../../components/Loading.vue";
import ModalDelete from "./components/ModalDelete.vue";
import ModalLocation from "./components/ModalLocation.vue";
import ModalEmployee from "./components/ModalEmployee.vue";
import ModalIncidence from "./components/ModalIncidence.vue";
import { cilPlus, cilSave, cilLocationPin, cilPrint, cilUser } from '@coreui/icons';

export default {
    name: 'Payroll',
    components: {
        Loading,
        ModalDelete,
        GanttElastic,
        ModalLocation,
        ModalEmployee,
        ModalIncidence,
    },
    data() {
        return {
            isLoading: true,
            userDelete: null,
            taskDelete: null,
            employee_id: null,
            userIcon: cilUser,
            printIcon: cilPrint,
            employeeModal: true,
            deleteUserModal: false,
            deleteTaskModal: false,
            options: {
                taskMapping: {
                    progress: "percent"
                },
                maxRows: 100,
                title: {
                    label: "Your project title as html (link or whatever...)",
                    html: false
                },
                row: {
                    height: 24
                },
                calendar: {
                    hour: {
                        display: false
                    }
                },
                chart: {
                    progress: {
                        bar: false
                    },
                    expander: {
                        display: true
                    }
                },
                taskList: {
                    expander: {
                        straight: false
                    },
                    columns: [
                        {
                            id: 1,
                            label: "ID",
                            value: "id",
                            width: 50
                        },
                        {
                            id: 2,
                            label: "Usuario",
                            value: "label",
                            width: 200,
                            expander: true,
                            html: true,
                        },
                        {
                            id: 2,
                            label: "Salario",
                            value: "salary",
                            width: 150,
                            expander: true,
                            html: true,
                        }
                    ]
                },
                locale: {
                    name: "es",
                    Now: "Now",
                    "X-Scale": "Zoom-X",
                    "Y-Scale": "Zoom-Y",
                    "Task list width": "Task list",
                    "Before/After": "Expand",
                    "Display task list": "Task list"
                }
            },
            tasks: [
                {
                    id: 0,
                    label: "Nomina",
                    start: this.getMonday(),
                    duration: 7 * 24 * 60 * 60 * 1000,
                    type: "project",
                }
            ],
            tasksAll: [],
            locations: [],
            employees: [],
            incidences: [],
            fullIncidences: [],
            dynamicStyle: {},
            addIcon: cilPlus,
            saveIcon: cilSave,
            locationModal: false,
            incidenceModal: false,
            locationIcon: cilLocationPin,
            userIcon: cilUser,
            incidence: {
                amount: '',
                discount: false,
                incidence_id: '',
                percentage: false,
                employee_id: this.employee_id,
            },
            location: {
                day: [
                    {
                        id: 1,
                        value: false
                    },
                    {
                        id: 2,
                        value: false
                    },
                    {
                        id: 3,
                        value: false
                    },
                    {
                        id: 4,
                        value: false
                    },
                    {
                        id: 5,
                        value: false
                    },
                    {
                        id: 6,
                        value: false
                    },
                    {
                        id: 7,
                        value: false
                    }
                ],
                amount: '',
                location_id: '',
                percentage: false,
                employee_id: this.employee_id,
            },
            week: 0,
        };
    },
    methods: {
        getLastWeek() {
            this.week--;
            this.getDataPayroll(this.week);
        },
        getCurrentWeek(employeeId) {
            if (employeeId != null) {
                console.log("employeeId");
                console.log(employeeId);
                this.employee_id = employeeId;
            }

            this.week = 0;
            this.employeeModal = false;
            this.getDataPayroll(this.week);
        },
        getBeforeWeek() {
            this.week++;
            this.getDataPayroll(this.week);
        },
        getDataPayroll(week) {
            let self = this;
            self.isLoading = true;

            let url = week ? `api/payrolls?week=${week}` : `api/payrolls`;

            axios.get(url)
                .then(response => {
                    let currenWeekExists = false;

                    let currentData = [];

                    if (response.data) {
                        if (response.data.data) {
                            response.data.data.payroll.forEach(function(record) {
                                if (record.id == 0 || (record.id == self.employee_id || record.parentId == self.employee_id )) {
                                    currentData.push(record);
                                }
                            });

                            self.tasksAll = response.data.data.payroll;
                            self.tasks = currentData;
                            currenWeekExists = true;
                        }
                    }

                    if (!currenWeekExists) {
                        currentData = [
                            {
                                id: 0,
                                label: "Nomina",
                                start: self.getMonday(week),
                                duration: 7 * 24 * 60 * 60 * 1000,
                                type: "project",
                            }
                        ];

                        let currentTaks = [
                            {
                                id: 0,
                                label: "Nomina",
                                start: self.getMonday(week),
                                duration: 7 * 24 * 60 * 60 * 1000,
                                type: "project",
                            }
                        ];

                        axios.get(`api/employees`)
                            .then(response => {
                                response.data.data.forEach(function(record) {
                                    let currTask = {
                                        id: record.id,
                                        parentId: 0,
                                        label: record.name + " - " + new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(record.salary),
                                        salary: new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(record.salary),
                                        baseSalary: record.salary,
                                        totalSalary: record.salary,
                                        start: self.getMonday(week),
                                        duration: 7 * 24 * 60 * 60 * 1000,
                                        type: "milestone",
                                        style: {
                                            base: {
                                                fill: '#0287D0',
                                                stroke: '#0077C0'
                                            }
                                        }
                                    };

                                    if (record.id == self.employee_id) {
                                        currentData.push(currTask);
                                    }

                                    currentTaks.push(currTask);
                                });

                                self.tasks = currentData;
                                self.tasksAll = currentTaks;
                                self.$toast.error("No existe informacion para la semana seleccionada");
                            });
                    }

                    self.isLoading = false;
                }).catch(error => {
                    if (error.response.status === 401) {
                        self.$router.push({ path: '/login' });
                    } else {
                        self.$toast.error(error.response.data.message);
                        self.isLoading = false;
                    }
                });
        },
        deleteTask() {
            let self = this;
            let userId = self.taskDelete.parentId;
            let amount = self.taskDelete.totalSalary;

            let user = self.tasks.find(element => element.id == userId);

            if (amount > 0) {
                user.totalSalary-=amount;
            } else {
                let currentAmount = Math.abs(amount);
                user.totalSalary+=currentAmount;
            }

            user.salary = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(user.totalSalary);

            const indexTask = self.tasks.indexOf(self.taskDelete);
            self.tasks.splice(indexTask, 1);
            self.taskDelete = null;
            self.deleteTaskModal = false;
        },
        savePayroll() {
            let self = this;
            self.isLoading = true;

            self.tasks.forEach(element => {
                let currentTaks = self.tasksAll.find(taks => taks.id == element.id);
                if (currentTaks) {
                    currentTaks = element;
                } else {
                    self.tasksAll.push(element);
                }
            });

            var data = {
                "payroll": self.tasksAll
            };

            let url = self.week ? `api/payrolls?week=${self.week}` : `api/payrolls`;

            axios.post(url, data)
                .then(response => {
                    self.$toast.success("Se guardo la nomina");
                    self.isLoading = false;
                }).catch(error => {
                    if (error.response.status === 401) {
                        self.$router.push({ path: '/login' });
                    } else {
                        self.$toast.error(error.response.data.message);
                        self.isLoading = false;
                    }
                });
        },
        onTaskClick({event, data}) { 
            this.taskDelete = data;
            this.deleteTaskModal = true;
        },
        onMilestoneClick({event, data}) { 
            this.userDelete = data;
            this.deleteUserModal = true;
        },
        showModalIncidence() { 
            this.incidenceModal = true;
        },
        showModalLocation() { 
            this.locationModal = true;
        },
        addLocation() {
            let self = this;
            let isvalid = true;

            if (self.location.amount.toString().trim() == "") {
                isvalid = false;
                self.location.amount_valid = false;
            } else {
                self.location.amount_valid = true;
            }

            if (self.location.location_id.toString().trim() == "") {
                isvalid = false;
                self.location.location_valid = false;
            } else {
                self.location.location_valid = true;
            }

            self.location.employee_id = this.employee_id;

            let haveDays =  self.location.day.filter(element => element.value);

            if (haveDays.length < 1) {
                isvalid = false;
                self.location.day_valid = true;
            } else {
                self.location.day_valid = false;
            }

            if (isvalid) {
                let currentLocation =  self.locations.find(element => element.value == self.location.location_id);
                let locationId = "L"+self.location.employee_id.toString()+self.location.location_id.toString();
                let task = self.tasks.find(element => element.id == self.location.employee_id);
                let taskLocation = self.tasks.filter(element => element.id.toString().includes(locationId));

                if (taskLocation.length > 0) {
                    locationId = locationId+taskLocation.length;
                }

                let amount = 0;
                let baseSalary = task.baseSalary;
                let totalSalary = task.totalSalary;

                if (self.location.percentage) {
                    let percentage = parseFloat(self.location.amount);
                    amount = ((baseSalary*percentage)/100);
                } else {
                    let totalAmount = parseFloat(self.location.amount);
                    amount = totalAmount;
                }

                let locationLabel = "";
                let salaryLocation = "";

                totalSalary = (totalSalary+amount);
                salaryLocation = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
                locationLabel = (self.location.percentage ? "("+self.location.amount+"%)" : "("+new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(self.location.amount) + ")") + " - " + currentLocation.label;

                task.totalSalary = totalSalary;
                task.salary = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalSalary);

                let countDay = 0;
                let isUsage = false;
                var taskLocationLength = taskLocation.length > 0 ? taskLocation.length : 1;

                let currentDay = null;

                self.location.day.forEach(function(record) {  
                    if (record.value) {
                        countDay++;
                        currentDay = record;
                    } else {
                        if (countDay > 0) {
                            if (isUsage) {
                                taskLocationLength++;
                                locationId = locationId+taskLocationLength;
                            }

                            var initDay = currentDay.id - (countDay-1);
                            var dateInitial = self.getDayOfWeek(initDay);

                            let currentData = {
                                id: locationId,
                                parentId: self.location.employee_id,
                                label: locationLabel,
                                start: dateInitial,
                                duration: countDay * 24 * 60 * 60 * 1000,
                                type: "task",
                                amount: !isUsage ? self.location.amount : "0",
                                salary: !isUsage ? salaryLocation : "$0.00",
                                totalSalary: !isUsage ? amount : 0,
                                style: {
                                    base: {
                                        fill: "#008000",
                                        stroke: "#008000"
                                    }
                                }
                            }

                            self.tasks.push(currentData);

                            isUsage = true;
                        }

                        countDay = 0;
                    }
                });

                if (countDay > 0) {
                    if (isUsage) {
                        taskLocationLength++;
                        locationId = locationId+taskLocationLength;
                    }

                    var initDay = currentDay.id - (countDay-1);
                    var dateInitial = self.getDayOfWeek(initDay);

                    let currentData = {
                        id: locationId,
                        parentId: self.location.employee_id,
                        label: locationLabel,
                        start: dateInitial,
                        duration: countDay * 24 * 60 * 60 * 1000,
                        type: "task",
                        amount: !isUsage ? self.location.amount : "0",
                        salary: !isUsage ? salaryLocation : "$0.00",
                        totalSalary: !isUsage ? amount : 0,
                        style: {
                            base: {
                                fill: "#008000",
                                stroke: "#008000"
                            }
                        }
                    }

                    self.tasks.push(currentData);
                }

                self.locationModal = false;

                self.location = {
                    day: [
                        {
                            id: 1,
                            value: false
                        },
                        {
                            id: 2,
                            value: false
                        },
                        {
                            id: 3,
                            value: false
                        },
                        {
                            id: 4,
                            value: false
                        },
                        {
                            id: 5,
                            value: false
                        },
                        {
                            id: 6,
                            value: false
                        },
                        {
                            id: 7,
                            value: false
                        }
                    ],
                    amount: '',
                    employee_id: '',
                    location_id: '',
                    day_valid: false,
                    percentage: false,
                    employee_valid: null,
                    location_valid: null,
                    employee_valid: null,
                };
            }
        },
        addTask() {
            let self = this;
            let isvalid = true;

            if (self.incidence.amount.trim() == "") {
                isvalid = false;
                self.incidence.amount_valid = false;
            } else {
                self.incidence.amount_valid = true;
            }

            if (self.incidence.incidence_id.toString().trim() == "") {
                isvalid = false;
                self.incidence.incidence_valid = false;
            } else {
                self.incidence.incidence_valid = true;
            }

            self.incidence.employee_id = this.employee_id;

            if (isvalid) {
                let currentIncidence =  self.fullIncidences.find(element => element.id == self.incidence.incidence_id);
                let incidenceId = "I"+self.incidence.employee_id.toString()+self.incidence.incidence_id.toString();
                let task = self.tasks.find(element => element.id == self.incidence.employee_id);
                let taskIncidence = self.tasks.filter(element => element.id.toString().includes(incidenceId));

                if (taskIncidence.length > 0) {
                    incidenceId = incidenceId+taskIncidence.length;
                }

                let amount = 0;
                let baseSalary = task.baseSalary;
                let totalSalary = task.totalSalary;

                if (self.incidence.percentage) {
                    let percentage = parseFloat(self.incidence.amount);
                    amount = ((baseSalary*percentage)/100);
                } else {
                    let totalAmount = parseFloat(self.incidence.amount);
                    amount = totalAmount;
                }

                let salaryIncidence = "";
                let incidenceLabel = "";

                if (self.incidence.discount) {
                    totalSalary = (totalSalary-amount);
                    salaryIncidence =  "-"+new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
                    incidenceLabel = (self.incidence.percentage ? "(-"+self.incidence.amount+"%)" : "(-"+new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(self.incidence.amount) + ")") + " - " + currentIncidence.name;
                    amount = -parseFloat(amount.toString());
                } else {
                    totalSalary = (totalSalary+amount);
                    salaryIncidence = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
                    incidenceLabel = (self.incidence.percentage ? "("+self.incidence.amount+"%)" : "("+new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(self.incidence.amount) + ")") + " - " + currentIncidence.name;
                }

                task.totalSalary = totalSalary;
                task.salary = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalSalary);

                let currentData = {
                    id: incidenceId,
                    parentId: self.incidence.employee_id,
                    label: incidenceLabel,
                    start: self.getMonday(self.week),
                    duration: 7 * 24 * 60 * 60 * 1000,
                    type: "task",
                    amount: self.incidence.amount,
                    salary: salaryIncidence,
                    totalSalary: amount,
                    style: {
                        base: {
                            fill: currentIncidence.color,
                            stroke: currentIncidence.color
                        }
                    }
                };

                self.tasks.push(currentData);
                self.incidenceModal = false;

                self.incidence = {
                    amount: '',
                    employee_id: '',
                    discount: false,
                    incidence_id: '',
                    percentage: false,
                    amount_valid: null,
                    employee_valid: null,
                    incidence_valid: null,
                };
            }
        },
        tasksUpdate(tasks) {
            this.tasks = tasks;
        },
        optionsUpdate(options) {
            this.options = options;
        },
        styleUpdate(style) {
            this.dynamicStyle = style;
        },
        getMonday(week) {
            let currentDate = new Date();

            if(week != null && week != 0) {
                currentDate.setDate(currentDate.getDate() + week * 7);
            }

            var day = currentDate.getDay(),
                diff = currentDate.getDate() - day + (day == 0 ? -6 : 1);
            let dateMonday = new Date(currentDate.setDate(diff));
            dateMonday = new Date(dateMonday.getFullYear(), dateMonday.getMonth(), dateMonday.getDate(), 0, 0, 0);
            return dateMonday;
        },
        getDayOfWeek(day) {
            let currentDate = new Date();

            if(this.week != null && this.week != 0) {
                currentDate.setDate(currentDate.getDate() + this.week * 7);
            }

            let currentDay = currentDate.getDay();
            let dDiff = 0;

            if (currentDay != day) {
                dDiff = day - currentDay;
            }

            let dateWeekend = new Date(currentDate.setDate(currentDate.getDate()+dDiff));
            dateWeekend = new Date(dateWeekend.getFullYear(), dateWeekend.getMonth(), dateWeekend.getDate(), 0, 0, 0);

            return dateWeekend;
        }
    },
    mounted() {
        let self = this;

        axios.get(`api/employees`)
            .then(response => {
                response.data.data.forEach(function(record) {
                    self.employees.push({
                        value: record.id,
                        label: record.name,
                    });
                });

                self.isLoading = false;
            }).catch(error => {
                if (error.response.status === 401) {
                    self.$router.push({ path: '/login' });
                } else {
                    self.$toast.error(error.response.data.message);
                    self.isLoading = false;
                }
            });

        axios.get(`api/incidences`)
            .then(response => {
                self.fullIncidences = response.data.data;
                response.data.data.forEach(function(record){
                    self.incidences.push({
                        value: record.id,
                        label: record.name,
                    });
                });
            }).catch(error => {
                if (error.response.status === 401) {
                    self.$router.push({ path: '/login' });
                } else {
                    self.$toast.error(error.response.data.message);
                    self.isLoading = false;
                }
            });

        axios.get(`api/locations`)
            .then(response => {
                response.data.data.forEach(function(record){
                    self.locations.push({
                        value: record.id,
                        label: record.name,
                    });
                });
            }).catch(error => {
                if (error.response.status === 401) {
                    self.$router.push({ path: '/login' });
                } else {
                    self.$toast.error(error.response.data.message);
                    self.isLoading = false;
                }
            });
    }
}
</script>
